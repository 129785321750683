<template>
    <maintenance-page
        page-title="Bill Profile"
        page-icon="mdi-arrow-decision-outline"
        collection-name="billProfile"
        module="bill"
        done-redirect-url="/auth/?isHistory=true"
        @change="logForm"
    >
        <template v-slot:after-form>
            <page title="Lines" v-if="formValue">
                <smart-form :value="addLineFormValue" :schema="addLineSchema" />
                <template v-slot:header>
                    <toolbar :left-items="addLineToolbar" />
                </template>
            </page>
        </template>
    </maintenance-page>
</template>
<script>
import MaintenancePage from '@/components/pages/Maintenance';

const addLineSchema = {
            itemLink: {
                component: 'text-field',
                autocomplete: {
                    type: 'item',
                },
                label: 'Item',
                cols: 4,
                clearable: true,
                returnObject: true,
                hideNoData: true,
            },
            allocationPercentage: {
                component: 'currency-field',
                precision: 1,
                currencySymbol: '',
                label: 'Allocation Percentage',
                cols: 4,
                defaultValue: 100.0,
            },
        };

export default {
    name: 'BillProfileDetail',
    components: { MaintenancePage },
    data: () => ({
        formValue: {},
        addLineSchema,
        addLineFormValue: {},
        addLineToolbar: [{ id: 'add', onClick: this.addLine, preset: 'add' }],
    }),
    methods: {
        logForm(val) {
            this.formValue = val;
            console.log('billProfileDetail form value', val);
        },
        addLine() {
            console.log('addLine', this.addLineFormValue);
        },
    },
};
</script>
